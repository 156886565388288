import React, { useEffect, useState } from "react";
import './Pricing.scss';
import { Button } from "@mui/material";

const Pricing = () => {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 50;
          setScrolled(isScrolled);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollTo = (id: string) => {
        const yOffset = -100; // Adjust this value according to your fixed header height or any other padding you need
        const element = document.getElementById(id);
        const y = element != null ? element.getBoundingClientRect().top + window.pageYOffset + yOffset : 0;
        
        window.scrollTo({ top: y, behavior: 'smooth' });
    };
    
    return (
        <div className="pricing-container">
            <span className="pricing-title">
                Pricing
            </span>

            <div className="pricing-cards-container">
                <div className="pricing-card">
                    <span className="card-title">
                        Starter Package
                    </span>
                    <span className="card-amount-text">
                        $50
                    </span>
                    <span className="card-description">
                        For 30 mins
                    </span>
                    <Button className="card-button" variant="contained" onClick={() => scrollTo('contact')}> Book Now </Button>
                </div>

                <div className="pricing-card card-accent">
                    <span className="card-title accent-card-title">
                        Medium Package
                    </span>
                    <span className="card-amount-text accent-card-amount-text">
                        $80
                    </span>
                    <span className="card-description accent-card-description">
                        For 60 mins
                    </span>
                    <Button className="card-button accent-card-button" variant="contained" onClick={() => scrollTo('contact')}> Book Now </Button>
                </div>

                <div className="pricing-card">
                    <span className="card-title">
                        Deluxe Package
                    </span>
                    <span className="card-amount-text">
                        $120
                    </span>
                    <span className="card-description">
                        For 90 mins
                    </span>
                    <Button className="card-button" variant="contained" onClick={() => scrollTo('contact')}> Book Now </Button>
                </div>
            </div>
        </div>
    )
}

export default Pricing;