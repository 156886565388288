import React, {useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import './Profile.component.scss';
import { AccountCircle, Logout } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { AppDispatch } from "src/state/store";
import { logout } from "src/state/user/userSlice";
import CustomSnackbar from "../custom-snackbar-component/CustomSnackbar.component";

const Profile: React.FC = () => {

    const loginState = useSelector((state: any) => state.user);
    // console.log('login state profile: ', loginState);
    const dispatch = useDispatch<AppDispatch>();
    const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
    // const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    // const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    // const [severity, setSeverity] = useState<Severity>('success');
    // useEffect(() => {
    //     if ( loginState.isLoggedIn === false ) {
    //         setSeverity('success');
    //         setSnackbarMessage('You have successfully logged out!');
    //         setOpenSnackbar(true);
    //         console.log('snackbar: ', openSnackbar);
    //     }
    // }, [loginState]);
    const openMenu = Boolean(menuEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuEl(null);
    };
    const handleLogout = () => {
        dispatch(logout(loginState));
        
    }

    return (
        <>
            <IconButton 
                area-label="profile"
                onClick={handleMenuClick}
            >
                <AccountCircle color="primary"/>
            </IconButton>
            <Menu
                anchorEl={menuEl}
                open={openMenu}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', 'vertical': 'bottom'}}
            >
                <MenuItem onClick={handleMenuClose}>
                    <span>Welcome {loginState.username}</span>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )

}

export default Profile;