import React from 'react';
import UsernameFormPropsModel from '../models/usernameFormProps.model';
import { TextField, Typography } from '@mui/material';

const UsernameForm: React.FC<UsernameFormPropsModel> = ({usernameFormValues, usernameFormErrors, handleUsernameFormChange}) => {

    return (
        <>
            <Typography>
                Enter your registered email address inorder to get your username in an email.
            </Typography>
            <TextField
                required
                name='email'
                type='text'
                label='Email'
                placeholder='Enter Email ...'
                variant='filled'
                size='small'
                fullWidth
                value={usernameFormValues.email}
                onChange={handleUsernameFormChange}
                error={!!usernameFormErrors.email}
                helperText={usernameFormErrors.email}
            />
        </>
    )
}

export default UsernameForm;