import React from 'react';
import { TextField } from '@mui/material';
import LoginFormPropsModel from '../models/loginFormProps.model';


const LoginForm: React.FC<LoginFormPropsModel> = (props: LoginFormPropsModel) => {
    return (
        <>
            <TextField
                required
                name='username'
                label='Username'
                placeholder='Enter Username ...'
                variant='filled'
                size='small'
                fullWidth
                value={props.loginFormValues.username}
                onChange={props.handleLoginFormChange}
                error={!!props.loginErrors.username}
                helperText={props.loginErrors.username}
            />

            <TextField
                required
                name='password'
                type='password'
                label='Password'
                placeholder='Enter Password ...'
                variant='filled'
                size='small'
                fullWidth
                value={props.loginFormValues.password}
                onChange={props.handleLoginFormChange}
                error={!!props.loginErrors.password}
                helperText={props.loginErrors.password}
            />
        </>
    )
};

export default LoginForm;