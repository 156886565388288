import { TextField } from '@mui/material';
import React from 'react';
import SignupFormPropsModel from '../models/signupFormProps.model';

const SignupForm: React.FC<SignupFormPropsModel> = ({signupFormValues, signupErrors, handleSignupFormChange}) => {
    return (
        <>
            <div className="form-row">
                <TextField
                    required
                    name='firstname'
                    label='First Name'
                    placeholder='Enter First Name ...'
                    variant='filled'
                    size='small'
                    fullWidth
                    value={signupFormValues.firstname}
                    onChange={handleSignupFormChange}
                    error={!!signupErrors.firstname}
                    helperText={signupErrors.firstname}
                />

                <TextField
                    required
                    name='lastname'
                    type='text'
                    label='Last Name'
                    placeholder='Enter Last Name ...'
                    variant='filled'
                    size='small'
                    fullWidth
                    value={signupFormValues.lastname}
                    onChange={handleSignupFormChange}
                    error={!!signupErrors.lastname}
                    helperText={signupErrors.lastname}
                />
            </div>

            <div className="form-row">
                <TextField
                    required
                    name='username'
                    label='Username'
                    placeholder='Enter Username ...'
                    variant='filled'
                    size='small'
                    fullWidth
                    value={signupFormValues.username}
                    onChange={handleSignupFormChange}
                    error={!!signupErrors.username}
                    helperText={signupErrors.username}
                />

                <TextField
                    required
                    name='phone_number'
                    type='tel'
                    label='Phone Number'
                    placeholder='Enter Phone Number ...'
                    variant='filled'
                    size='small'
                    fullWidth
                    value={signupFormValues.phone_number}
                    onChange={handleSignupFormChange}
                    error={!!signupErrors.phone_number}
                    helperText={signupErrors.phone_number}
                />
            </div>

            <TextField
                required
                name='email'
                type='text'
                label='Email'
                placeholder='Enter Email ...'
                variant='filled'
                size='small'
                fullWidth
                value={signupFormValues.email}
                onChange={handleSignupFormChange}
                error={!!signupErrors.email}
                helperText={signupErrors.email}
            />

            <div className="form-row">
                <TextField
                    required
                    name='password'
                    label='Password'
                    type='password'
                    placeholder='Enter Password ...'
                    variant='filled'
                    size='small'
                    fullWidth
                    value={signupFormValues.password}
                    onChange={handleSignupFormChange}
                    error={!!signupErrors.password}
                    helperText={signupErrors.password}
                />

                <TextField
                    required
                    name='c_password'
                    type='password'
                    label='Confirm Password'
                    placeholder='Enter Confirm Password ...'
                    variant='filled'
                    size='small'
                    fullWidth
                    value={signupFormValues.c_password}
                    onChange={handleSignupFormChange}
                    error={!!signupErrors.c_password}
                    helperText={signupErrors.c_password}
                />
            </div>
        </>
    )
};

export default SignupForm;