import React, {useState} from 'react';
import './Appointment.scss';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Snackbar } from '@mui/material';

const Appointment = () => {

    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: ''
    });

    const validate = () => {
        let temp = {
            name: '',
            email: '',
            message: ''
        };
        temp.name = formValues.name ? '' : 'This field is required';
        temp.email = (/^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})$/).test(formValues.email) ? "" : "Email is not valid.";
        temp.message = formValues.message ? "" : "This field is required.";

        setErrors({
            ...temp
        });

        return Object.values(temp).every(x => x === '');
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (validate()) {
            console.log('Valid Form', formValues);
            // Here you can add further steps, e.g., sending data to a server.
            try {
                const response = await fetch('http://localhost:5050/api/email/contactemail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin':'*'
                    },
                    body: JSON.stringify(formValues)
                });

                const result = await response.json();
                console.log(result);
                if ( result && result.success == true ) {
                    setSnackbarMessage('Email sent successfully!');
                    setOpen(true);
                    setFormValues({name: '', email: '', message: ''});
                } else {
                    setSnackbarMessage('Something went wrong.');
                    setOpen(true);
                }
            } catch (error) {
                console.error('Failed to send email:', error);
                setSnackbarMessage('Something went wrong.');
                setOpen(true);
            }
        }
    };

    const handleChange = (e: any) => {
        // console.log('E: ', e);
        const { name, value } = e.target;
        // console.log('name: ', name, value);
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleContactNumberCLick = () => {
        const phoneNumber = '14244392453';
        const smsUrl = `sms:${phoneNumber}`;
        window.location.href = smsUrl;
    }

    const handleMapClick = () => {
        const address = '18810 Preston Rd, Dallas, TX 75252';
        const mapUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
        window.open(mapUrl, '_blank');
    }

    const [open, setOpen] = useState(false);

    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSnackbarClose = () => {
        setOpen(false);
    }

    return (
        <div id='contact' className='appointment-container'>
            <div className='appointment-left-section'>
                <span className='left-section-title'>
                    Make an Appointment
                </span>

                <div className='location-container'>
                    <div className='location-title'>
                        <LocationOnIcon />
                        <span>LOCATION</span>
                    </div>
                    <div className='location-content'>
                        <a className='map' onClick={handleMapClick}>18810 Preston Rd, Dallas, TX 75252</a>
                    </div>
                </div>
                <Divider variant='middle' orientation='horizontal' flexItem />
                <div className='hours-contact-container'>
                    <div className='hours-container'>
                        <div className='hours-title'>
                            <WatchLaterIcon />
                            <span>WORKING HOURS</span>
                        </div>
                        <div className='hours-content'>
                            <span>Daily Open: 9am - 9pm</span>
                        </div>
                    </div>
                    <Divider variant='middle' orientation='vertical' flexItem />
                    <div className='contact-container'>
                        <div className='contact-title'>
                            <PhoneIcon />
                            <span>CONTACT</span>
                        </div>
                        <div className='contact-content'>
                            <span>Phone: </span><a className='sms' onClick={handleContactNumberCLick}>+1 424-439-2453</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='appointment-right-section'>
                <div className='get-in-touch-card'>
                    <div className='card-title'>
                        Get in Touch
                    </div>

                    <form className='form-container'>
                            <TextField
                                required
                                name='name'
                                label='Name'
                                placeholder='Enter Name ...'
                                variant='filled'
                                size='small'
                                fullWidth
                                value={formValues.name}
                                onChange={handleChange}
                                error={!!errors.name}
                                helperText={errors.name}
                            />

                            <TextField
                                required
                                name='email'
                                label='Email'
                                placeholder='Enter Email ...'
                                variant='filled'
                                size='small'
                                fullWidth
                                value={formValues.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                            />

                            <TextField
                                required
                                name='message'
                                label='Message'
                                placeholder='Enter Message ...'
                                variant='filled'
                                multiline
                                rows={2}
                                size='small'
                                fullWidth
                                value={formValues.message}
                                onChange={handleChange}
                                error={!!errors.message}
                                helperText={errors.message}
                            />

                            <Button
                                variant='contained'
                                className='primary-button'
                                startIcon={
                                    <SendIcon className='send-icon' />
                                }
                                onClick={handleSubmit}
                            >Send
                            </Button>

                            <Snackbar
                                open={open}
                                autoHideDuration={5000}
                                message={snackbarMessage}
                                onClose={handleSnackbarClose}
                            />
                    </form>
                </div>
            </div>
        </div>
    )
};

export default Appointment;