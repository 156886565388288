import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Create a severity type using union of string literals
type Severity = 'error' | 'warning' | 'info' | 'success';

interface CustomSnackbarProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string;
    severity: Severity;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

Alert.displayName = 'Alert'; // Set display name for the forwardRef component

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, setOpen, message, severity }) => {
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return; // Do nothing if the snackbar is closed by clicking away
      }
      setOpen(false); // Close the snackbar
    };

    const handleClose1 = () => {
        setOpen(false);
    }
  
    return (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    );
};
  
export default CustomSnackbar;
  
