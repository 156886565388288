import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetError } from "src/state/user/userSlice";
import './Login.component.scss';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField } from "@mui/material";

import usePost from "src/custom-hooks/usePost";
import axios from "axios";
import environment from "src/ennvironment/env.dev";
import CustomSnackbar from "../custom-snackbar-component/CustomSnackbar.component";
import { fetchUserData } from "src/state/user/userSlice";
import { AppDispatch, RootState } from "src/state/store";
import loginType from "./types/loginTypes";
import LoginForm from "./components/LoginForm";
import SignupForm from "./components/SignupForm";
import LoginFormValuesModel from "./models/loginFormValues.model";
import SignFormValuesModel from "./models/signupFormValues.model";
import UsernameFormValuesModel from "./models/usernameFormValues.model";
import UsernameForm from "./components/UsernameForm";
import ResetPasswordFormValuesModel from "./models/resetPasswordFormValues.model";
import ResetPasswordForm from "./components/ResetPasswordForm";

// Create a severity type using union of string literals
type Severity = 'error' | 'warning' | 'info' | 'success';

interface LoginComponentProps {
    openLogin: boolean;
    handleOpenLogin: (open: boolean) => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

const LoginComponent: React.FC<LoginComponentProps> = ({openLogin, handleOpenLogin}) => {


    const [open, setOpen] = useState(openLogin); // login dialog

    const [title, setTitle] = useState('Login');

		const [isLogin, setIsLogin] = useState(true);

		const [formTypeLable, setFormTypeLabel] = useState('');

		const [formType, setFormType] = useState<loginType>('login');
		const [formTypeButton, setFormTypeButton] = useState('');

		const [loginFormValues, setloginFormValues] = useState<LoginFormValuesModel>({
			username: '',
			password: ''
		});

		const [loginErrors, setLoginErrors] = useState<LoginFormValuesModel>({
			username: '',
			password: ''
		});

		const validateLoginForm = () => {
			let temp = {
				username: '',
				password: ''
			};
			temp.username = loginFormValues.username ? '' : 'Username is required.';
			temp.password = loginFormValues.password ? "" : "Password is required.";

			setLoginErrors({
					...temp
			});

			return Object.values(temp).every(x => x === '');
		};

		const [signupFormValues, setSignupFormValues] = useState<SignFormValuesModel>({
			firstname: '',
			lastname: '',
			username: '',
			phone_number: '',
			email: '',
			password: '',
			c_password: '',
		});

		const [signupErrors, setSignupErrors] = useState<SignFormValuesModel>({
			firstname: '',
			lastname: '',
			username: '',
			phone_number: '',
			email: '',
			password: '',
			c_password: '',
		});

		const validateSignupForm = () => {
			let temp = {
				firstname: '',
				lastname: '',
				username: '',
				phone_number: '',
				email: '',
				password: '',
				c_password: '',
			};
			temp.firstname = signupFormValues.firstname ? (/^[a-zA-Z]{2,20}$/).test(signupFormValues.firstname) ? '' : 'Only Characters are allowed between 2 to 20 characters.' : 'First Name is required.';
			temp.lastname = signupFormValues.lastname ? (/^[a-zA-Z]{2,20}$/).test(signupFormValues.lastname) ? '' : 'Only Characters are allowed between 2 to 20 characters.' : 'Last Name is required.';
			temp.username = signupFormValues.username ? (/^[a-zA-Z0-9]{3,20}$/).test(signupFormValues.username) ? '' : 'Only Characters/digits are allowed between 3 to 20 characters.' : 'Username is required.';
			temp.phone_number = signupFormValues.phone_number ? (/^[0-9]{10}$/).test(signupFormValues.phone_number) ? '' : 'Phone Number must have 10 digits.' : 'Phone Number is required.';
			temp.email = signupFormValues.email ? (/^([a-zA-Z0-9._-]+@[a-zA-Z0-[9.-]+\.[a-zA-Z]{2,6})$/).test(signupFormValues.email) ? "" : "Email is not valid." : 'Email is required.';
			temp.password = signupFormValues.password ? signupFormValues.password.length > 7 && signupFormValues.password.length < 21 ? '' : 'Password should be between 8 to 20 characters.' : "Password is required.";
			temp.c_password = signupFormValues.c_password ? signupFormValues.c_password == signupFormValues.password ? '' : 'Passwords does not match.' : 'Confirm Password is required.';

			setSignupErrors({
					...temp
			});

			return Object.values(temp).every(x => x === '');
		};

		const [usernameFormValues, setUsernameFormValues] = useState<UsernameFormValuesModel>({
			email: ''
		});

		const [usernameFormErrors, setUsernameFormErrors] = useState<UsernameFormValuesModel>({
			email: ''
		});

		const validateUsernameForm = () => {
			let temp = {
				email: ''
			};
			temp.email = usernameFormValues.email ? (/^([a-zA-Z0-9._-]+@[a-zA-Z0-[9.-]+\.[a-zA-Z]{2,6})$/).test(usernameFormValues.email) ? '' : 'Email is not valid.' : 'Email is required.';

			setUsernameFormErrors({
				...temp
			});

			return Object.values(temp).every(x => x === '');
		}

		const [resetPasswordFormValues, setResetPasswordFormValues] = useState<ResetPasswordFormValuesModel>({
			username: '',
			temp_password: '',
			new_password: '',
			c_new_password: ''
		});

		const [resetPasswordErrors, setResetPasswordErrors] = useState<ResetPasswordFormValuesModel>({
			username: '',
			temp_password: '',
			new_password: '',
			c_new_password: ''
		});

		const validateResetPasswordForm = () => {
			let temp: ResetPasswordFormValuesModel = {
				// username: '',
				temp_password: '',
				new_password: '',
				c_new_password: ''
			}

			// temp.username = resetPasswordFormValues.username ? '' : 'Username is required.';
			temp.temp_password = resetPasswordFormValues.temp_password ? '' : 'Temporary Password is required.';
			temp.new_password = resetPasswordFormValues.new_password ? resetPasswordFormValues.new_password.length > 7 && resetPasswordFormValues.new_password.length < 21 ? '' : 'New Password should be between 8 to 20 characters.' : 'New Password is required.';
			temp.c_new_password = resetPasswordFormValues.c_new_password ? resetPasswordFormValues.new_password === resetPasswordFormValues.c_new_password ? '' : 'New Passwords does not match.' : 'Confirm New Password is required.';

			setResetPasswordErrors({
				...temp
			});

			return Object.values(temp).every(x => x === '');
		}

		// const [signupResponse, setSignupResponse] = useState<string>('');
		// const [loginResponse, setLoginResponse] = useState<any>(null);
		// const [loading, setLoading] = useState(false);
		// const [error, setError] = useState(null);

		const { isLoggedIn, username, loading, error } = useSelector((state: RootState) => state.user);
		const dispatch = useDispatch<AppDispatch>();

		const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
		const [snackbarMessage, setSnackbarMessage] = useState<string>('This is a success message!');
		const [severity, setSeverity] = useState<Severity>('success');

    useEffect(() => {
      setOpen(openLogin);
    }, [openLogin]);

    useEffect(() => { // to update state of the header component
        handleOpenLogin(open);
    }, [open]);

		useEffect(() => {
			switch (formType) {
				case 'login':
					setTitle('Login');
					setFormTypeLabel('Need an Account?');
					setFormTypeButton('Create Account');
				break;

				case 'signup':
					setTitle('Create an Account');
					setFormTypeLabel('Already have an Account?');
					setFormTypeButton('Login');
				break;

				case 'username':
					setTitle('Get Username');
				break;

				case 'password':
					setTitle('Reset Password');
				
			}
			return () => {
				resetLoginForm();
			}
		}, [formType]);


		useEffect(() => {
			if ( isLoggedIn === true ) {
				setloginFormValues({
					username: '',
					password: ''
				});
				setLoginErrors({
					username: '',
					password: ''
				});
				setSignupFormValues({
					firstname: '',
					lastname: '',
					username: '',
					email: '',
					phone_number: '',
					password: '',
					c_password: ''
				});
				setSignupErrors({
					firstname: '',
					lastname: '',
					username: '',
					email: '',
					phone_number: '',
					password: '',
					c_password: ''
				});
				setOpen(false);
				setIsLogin(true);
				setTitle('Login');
				setSnackbarMessage('Logged in successfully!');
				setSeverity('success');
				setOpenSnackbar(true);
			}
			if ( isLoggedIn === false && error !== null ) {
				setSnackbarMessage(`${error}`);
				setSeverity('error');
				setOpenSnackbar(true);
			}
		}, [isLoggedIn,error]);

    const handleClose = () => {
        setOpen(false);
    };

		const handleLoginFormChange = (e: any) => {
			// console.log('E: ', e);
			const { name, value } = e.target;
			// console.log('name: ', name, value);
			setloginFormValues(prevValues => ({
					...prevValues,
					[name]: value
			}));
		};

		const handleSignupFormChange = (e: any) => {
			// console.log('E: ', e);
			const { name, value } = e.target;
			// console.log('name: ', name, value);
			setSignupFormValues(prevValues => ({
					...prevValues,
					[name]: value
			}));
		};

		const handleUsernameFormChange = (e: any) => {
			const { name, value } = e.target;

			setUsernameFormValues(prevValues => ({
				...prevValues,
				[name]: value
			}));
		};

		const handleResetPasswordFormChange = (e: any) => {
			const { name, value } = e.target;

			setResetPasswordFormValues(prevValues => ({
				...prevValues,
				[name]: value
			}));
		};

		const handleFormTypeButton = () => {
			if ( formType === 'login' || formType === 'username' || formType === 'password' ) {
				// setIsLogin(false);
				setFormType('signup');
			} else if ( formType === 'signup' ) {
				setFormType('login');
				// setIsLogin(true);
			}
		}

		const handleFormSubmit = () => {
			if ( formType === 'login' ) {
				if ( validateLoginForm() ) {
					console.log('login form: ', loginFormValues);

					dispatch(fetchUserData({...loginFormValues, url: 'login'}));

				}
			} else if ( formType === 'signup' ) {
				if ( validateSignupForm() ) {
					// delete signupFormValues['c_password'];
					console.log('signup form: ', signupFormValues);

					dispatch(fetchUserData({...signupFormValues, url: 'signup'}));
					
				}
			} else if ( formType === 'username' ) {
				if ( validateUsernameForm() ) {
					try {
						axios.post(environment.emailUrl + 'getusername', usernameFormValues)
						.then((resp: any) => {
							console.log('resp: ', resp);
							if ( resp && resp.data.success === true ) {
								setOpenSnackbar(true);
								setSeverity('success');
								setSnackbarMessage(resp.data.message);
								setFormType('login');
								setUsernameFormErrors({
									email: ''
								});
								setUsernameFormValues({
									email: ''
								});
							} else if ( resp && resp.data.success === false ) {
								setOpenSnackbar(true);
								setSeverity('warning');
								setSnackbarMessage(resp.data.message);
							}
						});
					} catch (err) {
						console.log('err: ', err);
					}
					
				}
			} else if ( formType === 'password' ) {
				if (validateResetPasswordForm()) {
					delete resetPasswordFormValues.c_new_password;

					try {
						axios.post(environment.userUrl + 'updatePassword', resetPasswordFormValues).then((resp: any) => {
							if ( resp && resp.data.success === true ) {
								setFormType('login');
								setOpenSnackbar(true);
								setSeverity('success');
								setSnackbarMessage(resp.data.message);
								dispatch(resetError(error));
								resetResetPasswordForm();
							} else if ( resp && resp.data.success === false ) {
								setOpenSnackbar(true);
								setSeverity('warning');
								setSnackbarMessage(resp.data.message);
							}
						})
					} catch ( err ) {
						setOpenSnackbar(true);
						setSeverity('warning');
						setSnackbarMessage('Something went wrong, try again later.');
					}
				}
			}
		};

		const handleGetUsernameClick = () => {
			setFormType('username');
		}

		const handleResetPassword = () => {
			setFormType('password');
		}

		const renderFormContent = () => {
			switch (formType) {
				case 'login':
					return <LoginForm loginFormValues={loginFormValues} loginErrors={loginErrors} handleLoginFormChange={handleLoginFormChange} />
				case 'signup':
					return <SignupForm signupFormValues={signupFormValues} signupErrors={signupErrors} handleSignupFormChange={handleSignupFormChange} />
				case 'username':
					return <UsernameForm usernameFormValues={usernameFormValues} usernameFormErrors={usernameFormErrors} handleUsernameFormChange={handleUsernameFormChange} />
				case 'password':
					return <ResetPasswordForm resetPasswordFormValues={resetPasswordFormValues} resetPasswordErrors={resetPasswordErrors} setResetPasswordErrors={setResetPasswordErrors} handleResetPasswordFormChange={handleResetPasswordFormChange} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setSnackbarMessage={setSnackbarMessage} />
			}
		}

		const resetLoginForm = () => {
			setloginFormValues({
				username: '',
				password: ''
			});
			setLoginErrors({
				username: '',
				password: ''
			});
		};

		const resetResetPasswordForm = () => {
			setResetPasswordFormValues({
				username: '',
				temp_password: '',
				new_password: '',
				c_new_password: ''
			});
			setResetPasswordErrors({
				username: '',
				temp_password: '',
				new_password: '',
				c_new_password: ''
			});
		}

    return(
			<>
			 <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title} 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        	<CloseIcon />
        </IconButton>
        <DialogContent dividers>
					
					<form className="form-container">
						{renderFormContent()}

						{(error === 'User does not exist' && formType === 'login') ? 
							<>
								<div className="form-type-container">
									<Typography>Forgot username?</Typography>
									<Button variant="text" onClick={handleGetUsernameClick}>Get Username</Button>
								</div>
							</>
							: <></>
						}

						{(error === 'Authentication failed' && formType === 'login') ?
							<>
								<div className="form-type-container">
									<Typography>Forgot password?</Typography>
									<Button variant="text" onClick={handleResetPassword}>Reset Password</Button>
								</div>
							</>
							: <></>
						} 
						
						<div className="form-type-container">
							<Typography>{formTypeLable}</Typography>

							<Button variant="text" onClick={handleFormTypeButton}>{formTypeButton}</Button> 
						</div>
					</form>

					
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleFormSubmit}>
            {title}
          </Button>
        </DialogActions>
      </BootstrapDialog>
			<CustomSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} severity={severity} />
			</>
     
    )
}


export default LoginComponent;


