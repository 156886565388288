import React, { useState } from 'react';
import ResetPasswordFormProps from '../models/resetPasswordFormProps.model';
import { Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import environment from 'src/ennvironment/env.dev';

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({resetPasswordFormValues, resetPasswordErrors, setResetPasswordErrors, handleResetPasswordFormChange, setOpenSnackbar, setSeverity, setSnackbarMessage}) => {

    const [showPasswordFields, setShowPaswordFields] = useState(false);
    const [hideButton, setHideButton] = useState(false);

    const validateUsername = () => {
        const temp = {
            username: ''
        };

        temp.username = resetPasswordFormValues.username ? '' : 'Username is required.';

        setResetPasswordErrors({
            ...temp
        });

        return Object.values(temp).every(x => x === '');
    }

    const handleTempPasswordClick = () => {
        console.log('valid: ', validateUsername());
        if (validateUsername()) {

            try {
                const payload = {
                    username: resetPasswordFormValues.username
                };
                axios.post(environment.emailUrl + 'getResetPasswordCode', payload).then((resp: any) => {
                    if ( resp && resp.data.success === true ) {
                        setShowPaswordFields(true);
                        setOpenSnackbar(true);
                        setSeverity('success');
                        setSnackbarMessage(resp.data.message);
                        setHideButton(true);
                    } else if ( resp && resp.data.success === false ) {
                        setOpenSnackbar(true);
                        setSeverity('warning');
                        setSnackbarMessage(resp.data.message);
                    }
                });
            } catch (err) {
                if (err ) {
                    setOpenSnackbar(true);
                    setSeverity('warning');
                    setSnackbarMessage('Something went wrong.');
                    console.log('err: ', err);
                }
            }
        }
    }

    return (
        <>
            <Typography>
                Enter username to get temporary password via registered email.
            </Typography>
            <TextField
                required
                name='username'
                label='Username'
                placeholder='Enter Username ...'
                variant='filled'
                size='small'
                fullWidth
                value={resetPasswordFormValues.username}
                onChange={handleResetPasswordFormChange}
                error={!!resetPasswordErrors.username}
                helperText={resetPasswordErrors.username}
                disabled={showPasswordFields}
            />

            {(hideButton === false) ? 
                <>
                    <Button variant='text' onClick={handleTempPasswordClick}>Temporary Password</Button>            
                </> : <></>}

            {(showPasswordFields === true) ?
                <>
                    <TextField
                        required
                        name='temp_password'
                        label='Reset Password Code'
                        placeholder='Enter Reset Password Code ...'
                        variant='filled'
                        size='small'
                        fullWidth
                        value={resetPasswordFormValues.temp_password}
                        onChange={handleResetPasswordFormChange}
                        error={!!resetPasswordErrors.temp_password}
                        helperText={resetPasswordErrors.temp_password}
                    />

                    <TextField
                        required
                        name='new_password'
                        type='password'
                        label='New Password'
                        placeholder='Enter New Password ...'
                        variant='filled'
                        size='small'
                        fullWidth
                        value={resetPasswordFormValues.new_password}
                        onChange={handleResetPasswordFormChange}
                        error={!!resetPasswordErrors.new_password}
                        helperText={resetPasswordErrors.new_password}
                    />

                    <TextField
                        required
                        name='c_new_password'
                        type='password'
                        label='Confirm Password'
                        placeholder='Enter Confirm Password ...'
                        variant='filled'
                        size='small'
                        fullWidth
                        value={resetPasswordFormValues.c_new_password}
                        onChange={handleResetPasswordFormChange}
                        error={!!resetPasswordErrors.c_new_password}
                        helperText={resetPasswordErrors.c_new_password}
                    />
                </>
                :
                <></>
            }
        </>
    )
};

export default ResetPasswordForm;