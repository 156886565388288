import React from "react";
import './AboutMe.scss';

// import suku from '../../../../assets/images/about_me/suku-suki.png';

const aboutMe = () => {
    return (
        <div id="aboutme" className="about-me-container">
            <span className="about-me-title">
                About Me
            </span>

            <div className="about-me-content-container">
                <div className="about-me-image-container">
                    {/* <img src={suku} ></img> */}
                </div>

                <div className="about-me-content">
                    <span className="content-text">
                    Hello, my name is Sonia! I specialize in the art of massage, drawing upon ancient Thai techniques to guide your body and mind towards deeper well-being. My specialties are deep-tissue/Swedish massage, Thai massage, and aromatherapy.
                    I personalize each session to your unique needs, combining traditional stretches and acupressure points to ease away tension, improve flexibility, and boost your energy. 
                    I try to approach each client with genuine care and compassion. I take the time to understand your concerns and tailor the session to address them, whether it's relieving muscle aches, boosting relaxation, or enhancing your overall sense of well-being. It's not just about the physical touch; I strive to create a calming and rejuvenating experience that leaves you feeling empowered and revitalized.
                    I'm committed to continuous learning, regularly attending workshops and exploring new techniques to enhance my repertoire. I believe in the power of this ancient practice to bring balance and harmony to our lives, and I'm honored to share this gift with you.
                    </span>

                    <span className="content-text">
                    If you need a peaceful retreat from your hectic day-to-day, you can schedule an appointment today.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default aboutMe;

