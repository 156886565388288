import React from 'react';
import logo from './logo.svg';
import './App.scss';

import Landing from './pages/landing/Landing';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
   
    <Routes>
      <Route path='/' element={
        <div className='landing-container'>
          <Header />
          <Landing />
          <Footer />
        </div>
      }></Route>
    </Routes>
  );
}

export default App;
