import React, { useEffect, useState } from "react";
import './Services.scss';
import { Button } from "@mui/material";
import ServicesModel from "../../models/service-model";

interface ServiceProps {
    serviceList: ServicesModel[]
};

const Services: React.FC<ServiceProps> = (props) => {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 50;
          setScrolled(isScrolled);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollTo = (id: string) => {
        const yOffset = -100; // Adjust this value according to your fixed header height or any other padding you need
        const element = document.getElementById(id);
        const y = element != null ? element.getBoundingClientRect().top + window.pageYOffset + yOffset : 0;
        
        window.scrollTo({ top: y, behavior: 'smooth' });
    };
    
    const serviceList: ServicesModel[] = props.serviceList; 
    // console.log('service list: ', serviceList);

    return (
        <div id="services" className="services-container">
            <span className="services-title">
                Services
            </span>

            {serviceList.map((service, index) => (
                (index % 2 === 0) ? (
                    <div className="service-row">
                        <div className="service-card">
                            <span className="service-card-title">{service.name}</span>

                            <span className="service-card-description">{service.description}</span>

                            <Button className="service-card-button" variant="contained" onClick={() => scrollTo('contact')}>Book Now</Button>
                        </div>

                        <div className="service-image-container">
                            <img src={service.imageUrl} height={200} width={200} />
                            {/* {(() => {
                                if ( service.imageUrl.length > 0) {
                                    return 
                                }
                            })()} */}
                            
                        </div>
                    </div>
                ) : (
                    <div className="service-row">
                        <div className="service-image-container">
                            <img src={service.imageUrl} height={200} width={200} />
                        </div>

                        <div className="service-card">
                            <span className="service-card-title">{service.name}</span>

                            <span className="service-card-description">{service.description}</span>

                            <Button className="service-card-button" variant="contained" onClick={() => scrollTo('contact')}>Book Now</Button>
                        </div>
                    </div>
                )            
            ))}

            {serviceList.map((service, index) => (
                <div className="service-row-mobile">
                    

                    <div className="service-card">
                        <span className="service-card-title">{service.name}</span>

                        <div className="service-image-container">
                            <img src={service.imageUrl} height={200} width={200} />
                        </div>

                        <span className="service-card-description">{service.description}</span>

                        <Button className="service-card-button" variant="contained" onClick={() => scrollTo('contact')}>Book Now</Button>
                    </div>
                </div>
            ))}
            
        </div>
    )
}

export default Services;