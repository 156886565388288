import React, {useState, useEffect} from "react";
import './Header.scss';
import logo from '../../assets/images/logo/logo2.png';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuComponent from "../menu-component/Menu.component";
import LoginComponent from "../login-component/Login.component";
import { useSelector } from "react-redux";
import Profile from "../profile-component/Profile.component";
import CustomSnackbar from "../custom-snackbar-component/CustomSnackbar.component";

// Create a severity type using union of string literals
type Severity = 'error' | 'warning' | 'info' | 'success';

const Header = () => {

    const [scrolled, setScrolled] = useState(false);

    const loginState = useSelector((state: any) => state.user);
    console.log('login state: ', loginState);

    // const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    // const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    // const [severity, setSeverity] = useState<Severity>('success');
    // useEffect(() => {
    //     if ( loginState.isLoggedIn === false ) {
    //         setSeverity('success');
    //         setSnackbarMessage('You have successfully logged out!');
    //         setOpenSnackbar(true);
    //         console.log('snackbar: ', openSnackbar);
    //     }
    // }, [loginState]);

    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 50;
          setScrolled(isScrolled);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollTo = (id: string) => {
        const yOffset = -100; // Adjust this value according to your fixed header height or any other padding you need
        const element = document.getElementById(id);
        const y = element != null ? element.getBoundingClientRect().top + window.pageYOffset + yOffset : 0;
        
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const [openLogin, setOpenLogin] = useState(false);

    const handleLoginClick = () => {
        setOpenLogin(true);
        console.log('openLogin', openLogin);
    }

    const handleOpenLogin = (login: boolean) => {
        setOpenLogin(login);
    }

    return (
        <div className={`header-container ${scrolled ? 'scrolled' : ''}`}>
            <div className="header-logo">
                <img src={logo} height={40} width={40} alt="logo" />
            </div>
            <div className="header-text">
                <p>Sonia Day Spa</p> 
            </div>
            
            <div className="header-nav-links">
                <Stack spacing={2} direction="row" className="header-stack">
                    <Button className="header-button" variant="text">Home</Button>
                    <Button className="header-button" variant="text" onClick={() => scrollTo('aboutme')}>About Me</Button>
                    <Button className="header-button" variant="text" onClick={() => scrollTo('services')}>Services</Button>
                    <Button className="header-button" variant="text" onClick={() => scrollTo('contact')}>Contact</Button>
                    
                    
                </Stack>
            </div>

            <span className="flex-spacer desktop-view"></span>

            {loginState.username !== null ?
                <Profile /> : 
                // {/* <div className="header-nav-links"> */}
                <Button className="header-button desktop-view" variant="text" onClick={handleLoginClick}>Login</Button>
                // {/* </div> */}
            }
            
            <span className="flex-spacer mobile-view"></span>

            <div className="menu-container">
                <MenuComponent />
            </div>

            
            <LoginComponent openLogin={openLogin} handleOpenLogin={handleOpenLogin} />
            {/* <CustomSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} severity={severity} /> */}
       
        </div>
    )
}

export default Header;