import React from "react";
import './Menu.component.scss';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const ITEM_HEIGHT = 48;

const MenuComponent = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (link: string) => {
        scrollTo(link);
        setAnchorEl(null);
    };

    const scrollTo = (id: string) => {
        const yOffset = -100; // Adjust this value according to your fixed header height or any other padding you need
        const element = document.getElementById(id);
        const y = element != null ? element.getBoundingClientRect().top + window.pageYOffset + yOffset : 0;
        
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <div>
            <IconButton 
                aria-label="menu"
                id="menu-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MenuIcon className="menu-icon" />
            </IconButton>

            <Menu
                id="basic-menu"
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose('')}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => handleClose('home')}>Home</MenuItem>
                <MenuItem onClick={() => handleClose('aboutme')}>About Me</MenuItem>
                <MenuItem onClick={() => handleClose('services')}>Services</MenuItem>
                <MenuItem onClick={() => handleClose('contact')}>Contact</MenuItem>
            </Menu>
        </div>
    )
};

export default MenuComponent;