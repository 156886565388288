import React, { useEffect, useState } from "react";
import './Landing.scss';
import { Button } from "@mui/material";
import AboutMe from './components/about-me/AboutMe';
import Pricing from "./components/pricing/Pricing";
import Services from "./components/services/Services";
import Appointment from "./components/appointment/Appointment";
import ServicesModel from './models/service-model';

import deluxe_thai_massage from '../../assets/images/massage-services-images/deluxe-thai-massage.jpg';
import relax_massage from '../../assets/images/massage-services-images/relax-massage.jpg';
import traditional_thai_massage from '../../assets/images/massage-services-images/traditional-thai-massage.webp';


const Landing: React.FC = () => {

    // console.log('zzz:', deluxe_thai_massage);

    const servicesList: ServicesModel[] = [
        {
            name: "Deluxe Thai Massage",
            description: "Our full body treatment combines a variety of treatments, such as Traditional Thai Massage, Thai hot oil therapy, and hot stone therapy, that are personalized to your specific needs. With our considerable experience in Thai bodywork, you can be confident that your session will be tailored to your specific requirements. Each therapy is unique because your body changes and your needs change during the healing process.",
            imageUrl: deluxe_thai_massage,
        }, 
        {
            name: "Relax Massage",
            description: "A Swedish massage that covers the full body with soft to moderate pressure and fragrance-free oil. The primary objective of Swedish Massage is to relieve muscle tension while increasing blood circulation to the heart. This therapy is extremely useful, lowering muscle toxins, increasing blood oxygen levels, and improving general body flexibility.",
            imageUrl: relax_massage,
        },
        {
            name: "Traditional Thai Massage",
            description: "A Swedish massage that covers the full body with soft to moderate pressure and fragrance-free oil. The primary objective of Swedish Massage is to relieve muscle tension while increasing blood circulation to the heart. This therapy is extremely useful, lowering muscle toxins, increasing blood oxygen levels, and improving general body flexibility.",
            imageUrl: traditional_thai_massage,
        },
        // {
        //     name: "Deep Tissue Massage",
        //     description: "A deep tissue massage that covers the full body and includes muscle relief oil. This technique works on deeper muscle layers, addressing knots and easing tension points all over the body. It aims to relieve muscle tightness and release pressure points for improved comfort and relaxation, making it especially beneficial for athletes and people who spend a lot of time sitting.",
        //     imageUrl: "",
        // },
        // {
        //     name: "Reflexology Massage",
        //     description: "We use unscented oil for reflexology massage and divide the session into 30 minutes on the feet and 20 minutes on the hands.",
        //     imageUrl: "",
        // }, 
        // {
        //     name: "Aroma Therapy Massage",
        //     description: "A full-body relaxation massage that allows the client to choose their preferred aroma. This massage employs the use of essential oil-infused massage oil or lotion, which is made up of concentrated plant oils. In an aromatherapy massage, you either inhale or absorb essential oil molecules through your skin for a more therapeutic experience.",
        //     imageUrl: "",
        // }
    ];

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 50;
          setScrolled(isScrolled);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollTo = (id: string) => {
        const yOffset = -100; // Adjust this value according to your fixed header height or any other padding you need
        const element = document.getElementById(id);
        const y = element != null ? element.getBoundingClientRect().top + window.pageYOffset + yOffset : 0;
        
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <div className="landing-container">
            <div className="landing-carousal-container">
                <span className="carousal-title">Sonia Day Spa</span>

                <Button className="carousal-button" variant="contained" onClick={() => scrollTo('contact')}>Book An Appointment</Button>
            </div>

            <AboutMe />

            <Pricing />

            <Services serviceList={servicesList} />

            <Appointment />
        </div>
    )
}

export default Landing;