import React, {useState} from 'react';
import './Footer.scss';
import Divider from '@mui/material/Divider';
import CopyrightIcon from '@mui/icons-material/Copyright';

const Footer = () => {

    const handleMapClick = () => {
        const address = '18810 Preston Rd, Dallas, TX 75252';
        const mapUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
        window.open(mapUrl, '_blank');
    }

    const handlePhoneClick = () => {
        const phoneNumber = '14244392453';
        const smsUrl = `sms:${phoneNumber}`;
        window.location.href = smsUrl;
    }

    const handleMailClick = () => {
        const email = 'soniadayspa11@gmail.com';
        const mailUrl = `mailto:${email}`;
        window.open(mailUrl, '_blank');
    }

    return (
        <div className='footer-container'>
            <div className='container'>
                <div className='content'>
                    <div className='title'>
                        Sonia Day Spa
                    </div>
                    <div className='text'>
                        Our Spa body skin treatments are customized for you, designed to nourish, rejuvinate, and pamper your skin.
                    </div>
                </div>
                <div className='content'>
                    <div className='title'>
                        Navigation
                    </div>
                    <div className='text'>Home</div>
                    <div className='text'>Bookings</div>
                    <div className='text'>Services</div>
                    <div className='text'>Contact</div>
                </div>
                <div className='content'>
                    <div className='title'>
                        Get In Touch
                    </div>
                    <div className='text'>
                        <a onClick={handleMapClick}>18810 Preston Rd, Dallas, Tx 75252</a>
                    </div>
                    <div className='text'>
                        <a onClick={handlePhoneClick}>+1 424-439-2453</a>
                    </div>
                    <div className='text'>
                        <a onClick={handleMailClick}>soniadayspa11@gmail.com</a>
                    </div>
                </div>
            </div>
            <Divider className='footer-divider' orientation='horizontal' variant='middle' flexItem />
            <div className='container'>
                <span className='text'>
                    Copyright <CopyrightIcon className='copyright' /> 2024 Sonia Day Spa. Powered by Sonia Day Spa.
                </span>
            </div>
        </div>
    )
};

export default Footer;