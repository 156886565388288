import { createSlice, createAsyncThunk, PayloadAction, nanoid } from "@reduxjs/toolkit";
import environment from "src/ennvironment/env.dev";

interface UserApiResponse {
    token: string;
    username: string;
    _id: string;
    errorMessage?: string;  // Optional field for error messages
}

interface FetchUserDataArgs {
    username: string;
    password: string;
    firstname?: string;
    lastname?: string;
    phone_number?: string;
    email?: string;
    c_password?: string;
    url?: string;
}

interface UserState {
    isLoggedIn: boolean;
    username: string | null;
    token: string | null;
    _id: string | null,
    loading: boolean;
    error: string | unknown;
};

const initialState: UserState = {
    isLoggedIn: false,
    username: null,
    token: null,
    _id: null,
    loading: false,
    error: null,
};

export const fetchUserData = createAsyncThunk<UserApiResponse, FetchUserDataArgs, { rejectValue: string | unknown }>(
    'user/fetchUserData',
    async (args, { rejectWithValue }) => {
      try {
        const url = args.url;
        delete args.url;
        const response = await fetch(environment.authUrl + url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(args),
        });
        const data: UserApiResponse = await response.json();
        if (!response.ok) throw new Error(data.errorMessage || 'Failed to fetch');
            return data;
        } catch (error) {
            return rejectWithValue((error as Error).message);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state, action) => {
            state.isLoggedIn = false;
            state.token = null;
            state.username = null;
            state._id = null;
        },
        resetError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserData.fulfilled, (state, action: PayloadAction<UserApiResponse>) => {
                state.isLoggedIn = true;
                state.token = action.payload.token;
                state.username = action.payload.username;
                state._id = action.payload._id;
                state.loading = false;
            })
            .addCase(fetchUserData.rejected, (state, action: PayloadAction<string | unknown>) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export const { logout, resetError } = userSlice.actions;
export default userSlice.reducer;